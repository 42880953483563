<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <v-server-table
        class="pt-0"
        ref="table"
        name="alerts/alertsTable"
        :columns="columns"
        :options="options"
      >
        <div slot="afterFilterWrapper">
          <v-card-title class="float-left pa-0">
            <v-app-bar
              flat
              class="white pa-0"
              elevate-on-scroll
              dense
            >
              <v-toolbar-title
                data-cy="alerthistory_title"
              >
                {{ $t('alert.listing.title') }}
              </v-toolbar-title>
              <v-spacer />
            </v-app-bar>
          </v-card-title>
        </div>
        <div
          slot="id"
          slot-scope="props"
        >
          {{ props.row.id }}
        </div>
        <div
          slot="service_id"
          slot-scope="props"
        >
          {{ props.row.service_id }}
        </div>
        <div
          slot="create_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.create_date"
            :showtime="true"
          />
        </div>
        <div
          slot="hit_name"
          slot-scope="props"
        >
          <router-link
            color="primary"
            :to="{ name: 'NaturalPersonEdit', params: { np_id: props.row.person.id } }"
            v-if="props.row.person"
            :data-cy="`to_NaturalPersonEdit_${props.index}`"
          >
            {{ props.row.hit_name }}
          </router-link>
          <span
            v-else
            :data-cy="`to_NaturalPersonEdit_${props.index}`"
          >
            {{ props.row.hit_name }}
          </span>
        </div>
        <div
          slot="hit_status"
          slot-scope="props"
        >
          {{ (hit_status_options.find(x => x.value == props.row.hit_status) || { text: '' }).text }}
        </div>
        <div
          slot="exposition"
          slot-scope="props"
          style="max-width: 300px;"
        >
          {{ (exposition_options(props.row.hit_status).find(x => x.value == props.row.exposition) || { text: '' }).text }}
        </div>
        <!-- <div slot="remarks" slot-scope="props">
					<TextareaSafeDisplay :value="props.row.remarks" />
				</div> -->
        <div
          slot="action"
          slot-scope="props"
        >
          <v-btn
            icon
            @click="popup_alert_detail(props.row);"
            :data-cy="`alert_edit_btn_${props.index}`"
          >
            <v-icon>{{ props.row.hit_status == 0 ? 'mdi-pencil' : 'mdi-eye' }}</v-icon>
          </v-btn>
        </div>
      </v-server-table>
      <v-dialog
        v-model="dialog_alert_detail"
        max-width="90%"
      >
        <v-card>
          <PopupAlertDetail
            :records="dialog_alert_detail_record"
            @onSave="(form) => {
              $refs.table.getData();
              dialog_alert_detail = false;
              dialog_alert_detail_record = null;
            }"
            data-cy="alert_detail"
          />
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PopupAlertDetail from '@/components/popups/PopupAlertDetail'
import DateFormat from '@/components/common/DateFormat'
// import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'

export default {
  components: {
    PopupAlertDetail,
    DateFormat
    // TextareaSafeDisplay
  },
  computed: {
    ...mapGetters({
      options: 'alerts/listing_options',
      hit_status_options: 'alerts/hit_status_options',
      exposition_options: 'alerts/exposition_options'
    }),
    ...mapState('alerts', {
      requestLoading: state => state.listing.requestLoading,
      columns: state => state.listing.columns,
      dateColumns: state => state.listing.dateColumns
      // options: state => state.listing.options
    })
  },
  data: () => {
    return {
      dialog_alert_detail: false,
      dialog_alert_detail_record: null
    }
  },
  methods: {
    ...mapActions({
      // load: 'scan/lists',
      updateAlertInfo: 'scan/updateAlertInfo'
    }),
    popup_alert_detail (row) {
      // console.log(row);
      this.dialog_alert_detail = true
      this.dialog_alert_detail_record = row
    }
  },
  created () {
    // this.load()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  /* div#example1_paginate, div#example1_filter {
	text-align: right;
}
i.fa {
    width: 25px !important;
    text-align: center;
} */
</style>
